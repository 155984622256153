import React from "react";
import { RedocStandalone } from "redoc";
import docs from "./docs.json";
import "./App.css";
function App() {
  return (
    <div className="App">
      {/* Logo with the url https://helitaxii.com/assets/HeliTaxii-logo.png */}
      <div className="logo-container">
        <img
          src="https://helitaxii.com/assets/HeliTaxii-logo.png"
          alt="HeliTaxii Logo"
          style={{ height: "60px" }}
        />
      </div>
      <div className="dummy-nav-container"></div>
      <div className="redoc-container">
        <RedocStandalone
          spec={docs}
          options={{
            hideDownloadButton: true,
            expandDefaultServerVariables: true,
            showExtensions: true,
            theme: {
              sidebar: {
                backgroundColor: "white",
                textColor: "#005299",
              },
              colors: {
                primary: {
                  main: "#005299",
                },
              },
              typography: {
                headings: {
                  fontWeight: "bold",
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default App;
